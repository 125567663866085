import * as React from 'react';
import Box from '@mui/material/Box';
import { HeaderStyled } from './Header.styles';
import { Logo } from '../Logo/Logo';

export const Header: React.FC = () => (
  <HeaderStyled position="fixed" data-testid="header__component">
    <Box px={t => t.spacing(18)}>
      <Logo />
    </Box>
  </HeaderStyled>
);
