export const formatDuration = (durationMinutes: number): string => {
  if (!Number.isSafeInteger(durationMinutes)) throw new Error('Value of duration must be integer');

  const days = Math.floor(durationMinutes / (24 * 60));
  const remainingHoursMinutes = durationMinutes % (24 * 60);
  const hours = Math.floor(remainingHoursMinutes / 60);
  const remainingMinutes = durationMinutes % 60;

  if (days > 0) {
    return `${days} d ${hours} h ${remainingMinutes} min`;
  } else if (hours > 0) {
    return `${hours} h ${remainingMinutes} min`;
  } else {
    return ` ${remainingMinutes} min`;
  }
};

export default formatDuration;
