import React, { useState } from 'react';
import { SiteDetailsTabProps } from '../../../operations-and-maintenance/pages/SiteDetails/tabs/types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CamerasTab from '../components/Cameras/Cameras';
import AlertsTab from '../components/Alerts/Alerts';

export const Security: React.FC<SiteDetailsTabProps> = ({ siteDetails, companyDetails }) => {
  const [alignment, setAlignment] = useState<string>('cameras');
  const handleToggleButtonChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  return (
    <>
      <ToggleButtonGroup
        size="small"
        data-testid="toggle__group"
        value={alignment}
        exclusive
        onChange={handleToggleButtonChange}
        sx={{ height: '40px', marginBottom: '24px' }}
      >
        <ToggleButton value="cameras" sx={{ width: '104px' }}>
          Cameras
        </ToggleButton>
        <ToggleButton value="alerts" sx={{ width: '104px' }}>
          Alerts
        </ToggleButton>
      </ToggleButtonGroup>
      {alignment === 'cameras' && <CamerasTab siteDetails={siteDetails} companyDetails={companyDetails} />}
      {alignment === 'alerts' && <AlertsTab siteDetails={siteDetails} companyDetails={companyDetails} />}
    </>
  );
};

export default Security;
