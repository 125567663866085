import { QueryClient, queryOptions } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { ApiClient } from '../../../../api';

export const siteDetailsQuery = (siteId: number, enabled = true) =>
  queryOptions({
    queryKey: ['site', 'details', { siteId }],
    queryFn: () => ApiClient.operationsAndMaintenance.getSiteById(siteId),
    enabled: enabled
  });

export const companyDetailsQuery = (companyId: number, enabled = true) =>
  queryOptions({
    queryKey: ['company', 'details', { companyId }],
    queryFn: () => ApiClient.operationsAndMaintenance.getCompanyById(companyId),
    enabled: enabled
  });

export const deviceDetailsQuery = (deviceId: number, enabled = true) =>
  queryOptions({
    queryKey: ['device', 'details', { deviceId }],
    queryFn: () => ApiClient.operationsAndMaintenance.getDeviceById(deviceId),
    enabled: enabled
  });

export const createDeviceDetailsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const siteId = params.siteId;
    const companyId = params.companyId;
    const deviceId = params.deviceId;
    const isValiddeviceId = !!deviceId && Number.isSafeInteger(Number.parseInt(deviceId));
    if (!isValiddeviceId) throw new Error(`Provided device id "${deviceId}" is invalid.`);
    const isValidId = !!siteId && Number.isSafeInteger(Number.parseInt(siteId));
    if (!isValidId) throw new Error(`Provided site id "${siteId}" is invalid.`);
    const isValid = !!companyId && Number.isSafeInteger(Number.parseInt(companyId));
    if (!isValid) {
      throw new Error(`Provided company id "${companyId}" is invalid.`);
    }
    const data = await queryClient.fetchQuery(companyDetailsQuery(Number.parseInt(companyId)));
    const siteData = await queryClient.fetchQuery(siteDetailsQuery(Number.parseInt(siteId)));
    const deviceData = await queryClient.fetchQuery(deviceDetailsQuery(Number.parseInt(deviceId)));

    return { siteData, data, deviceData };
  };
