import React from 'react';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { WidgetContainer } from '../../Overview.style';
import { formatFloatValue } from '../../../../../../../../utils/formatters/formatFloatValue';

interface ActualProjectedPowerProps {
  title: string;
  data?: {
    actual: number;
    expected: number;
    period: string;
  }[];
}

const ActualProjectedPower: React.FC<ActualProjectedPowerProps> = ({ title, data }) => {
  const theme = useTheme();
  if (!data) return null;

  const formattedData = data.map(({ actual, expected, period }) => ({
    actual,
    expected,
    period: new Date(period)
  }));

  const options: AgChartOptions = {
    autoSize: true,
    height: 350,
    data: formattedData,
    series: [
      {
        type: 'line',
        xKey: 'period',
        yKey: 'actual',
        yName: 'Actual',
        stroke: theme.efficiencyColors.good,
        strokeWidth: 2,
        marker: {
          fill: theme.efficiencyColors.good,
          stroke: theme.efficiencyColors.good,
          strokeWidth: 2,
          size: 0
        },
        tooltip: {
          renderer: params => {
            const { datum, xKey, yKey, title } = params;
            const content = `
              ${dayjs(datum[xKey]).format('MM/DD/YY, HH:mm:ss')}: ${formatFloatValue(datum[yKey])} kW`;
            return {
              title,
              content
            };
          }
        }
      },
      {
        type: 'line',
        xKey: 'period',
        yKey: 'expected',
        yName: 'Expected',
        stroke: theme.efficiencyColors.outstanding,
        strokeWidth: 2,
        marker: {
          fill: theme.efficiencyColors.outstanding,
          stroke: theme.efficiencyColors.outstanding,
          strokeWidth: 2,
          size: 0
        },
        tooltip: {
          renderer: params => {
            const { datum, xKey, yKey, title } = params;
            const content = `
              ${dayjs(datum[xKey]).format('MM/DD/YY, HH:mm:ss')}: ${formatFloatValue(datum[yKey])} kW`;
            return {
              title,
              content
            };
          }
        }
      }
    ],
    axes: [
      {
        type: 'time',
        position: 'bottom',
        label: {
          format: '%d %b'
        },
        title: {
          text: 'Period'
        }
      },
      {
        type: 'number',
        position: 'left',
        title: {
          text: 'Kilowatts'
        }
      }
    ]
  };

  return (
    <WidgetContainer>
      <Typography variant="h6" mb="6px">
        {title}
      </Typography>
      <AgChartsReact options={options} />
    </WidgetContainer>
  );
};

export default ActualProjectedPower;
