import React from 'react';
import Box from '@mui/material/Box';

import { SiteDetailsTabProps } from '../types';
import TermsAndValuesList from './components/TermsAndValuesList/TermsAndValuesList';

const OverviewTab: React.FC<SiteDetailsTabProps> = ({ siteDetails, companyDetails }) => {
  return (
    <Box sx={{ flexGrow: 1 }} data-testid="overview-tab__component">
      <TermsAndValuesList siteId={siteDetails.id} companyId={companyDetails.id} />
    </Box>
  );
};

export default OverviewTab;
