import { useAuth } from '../../contexts/auth/auth';

export const useAccess = (companyId?: number) => {
  const { user } = useAuth();

  const isSystemUser = user?.settings_access === 'system_user';
  const isCompanyAdminFull = user?.settings_access === 'company_admin_full';
  const isCompanyView = user?.settings_access === 'company_view';

  const isFullAccess = isSystemUser || isCompanyAdminFull;
  const isUserParentCompany = isSystemUser ? true : user?.parent_company_id === companyId;

  return {
    isSystemUser,
    isCompanyAdminFull,
    isCompanyView,
    isFullAccess,
    isUserParentCompany
  };
};
