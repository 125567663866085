import * as React from 'react';
import { Instance } from '@popperjs/core';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SecurityIcon from '@mui/icons-material/Security';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { NavMenuButtonContainer } from './NavMenu.styles';
import { useNavigate, useMatches } from 'react-router-dom';
import { RouteHandle } from '../../../handles';

interface AnchorElTooltip extends React.PropsWithChildren {
  anchor: React.RefObject<HTMLDivElement>;
  title: string;
}

const AnchorElTooltip: React.FC<AnchorElTooltip> = ({ children, anchor, title }) => {
  const elementRef = React.useRef<HTMLDivElement>(null);
  const popperRef = React.useRef<Instance>(null);

  const handleMouseMove = () => {
    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <Tooltip
      title={title}
      placement="right"
      arrow
      slotProps={{
        tooltip: { sx: { bgcolor: '#121212', padding: '8px 12px', fontSize: '0.8rem', fontWeight: 500 } },
        arrow: { sx: { color: '#121212' } }
      }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: { enter: 700 } }}
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () =>
            new DOMRect(
              0,
              elementRef.current ? elementRef.current.getBoundingClientRect().top : 0,
              anchor.current ? anchor.current.getBoundingClientRect().width : 0,
              elementRef.current ? elementRef.current.getBoundingClientRect().height : 0
            )
        }
      }}
    >
      <Box ref={elementRef} onMouseMove={handleMouseMove}>
        {children}
      </Box>
    </Tooltip>
  );
};

const menuItems: [string, React.ReactNode, string, string, boolean][] = [
  ['dashboard', <DashboardIcon key="operations-and-maintenance" />, 'Dashboard', '/dashboard', false],
  [
    'operations-and-maintenance',
    <WhatshotIcon key="operations-and-maintenance" />,
    'O&M',
    '/operations-and-maintenance',
    false
  ],
  ['due-diligence', <FactCheckIcon key="due-diligence" />, 'Diligence', '/due-diligence', false],
  ['task-management', <QuickreplyIcon key="task-management" />, 'Task Management', '/', true],
  ['finance', <AccountBalanceWalletIcon key="finance" />, 'Finance', '/', true],
  ['security', <SecurityIcon key="security" />, 'Security', '/', true],
  ['asset-management', <AccountBalanceIcon key="asset-management" />, 'Asset Management', '/asset-management', false]
];

interface MenuItemProps {
  icon: React.ReactNode;
  title: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, title, active, disabled, onClick }) => (
  <NavMenuButtonContainer className={active ? 'active' : undefined} disabled={disabled} onClick={onClick}>
    <Grid container columns={15} alignItems="center">
      <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
        {icon}
      </Grid>
      <Grid item xs={11} display="flex" justifyContent="flex-start" alignItems="center">
        <Typography noWrap fontSize="16px" fontWeight="400" letterSpacing="0.15px">
          {title}
        </Typography>
      </Grid>
    </Grid>
  </NavMenuButtonContainer>
);

interface NavMenuProps {
  containerRef: React.RefObject<HTMLDivElement>;
  isMenuOpen: boolean;
  closeMenu: () => void;
}

export const NavMenu: React.FC<NavMenuProps> = ({ containerRef, isMenuOpen, closeMenu }) => {
  const navigate = useNavigate();
  const matches = useMatches();
  const currentModuleId =
    matches
      .map(({ handle }) => (handle instanceof RouteHandle ? handle.getModuleId() : null))
      .find(el => el !== null) || '';

  const menuItemClickHandler = (navigateTo: string) => () => {
    navigate(navigateTo);
    setTimeout(closeMenu, 100);
  };

  return (
    <Stack direction="column" width={t => t.spacing(30)}>
      {menuItems.map(([key, icon, title, route, disabled]) =>
        isMenuOpen ? (
          <MenuItem
            key={title}
            title={title}
            icon={icon}
            onClick={menuItemClickHandler(route)}
            active={key === currentModuleId}
            disabled={disabled}
          />
        ) : (
          <AnchorElTooltip title={disabled ? `${title} (coming soon)` : title} key={key} anchor={containerRef}>
            <MenuItem
              title={title}
              icon={icon}
              onClick={menuItemClickHandler(route)}
              active={key === currentModuleId}
              disabled={disabled}
            />
          </AnchorElTooltip>
        )
      )}
    </Stack>
  );
};
