import { QueryClient, queryOptions } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { ApiClient } from '../../../../api';
import { siteDetailsQuery } from '../../../../pages/AssetManagementSiteDetails/loader';

export const deviceDetailsQuery = (siteId: number, deviceId: number, enabled = true, throwOnError = false) =>
  queryOptions({
    queryKey: ['device', 'details', { siteId, deviceId }],
    queryFn: () => ApiClient.assetManagement.deviceById(siteId, deviceId),
    enabled: enabled,
    throwOnError: throwOnError ? true : undefined
  });

export const createAssetManagementDeviceDetailsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const siteId = params.siteId;
    const companyId = params.companyId;
    const deviceId = params.deviceId;

    if (!siteId || !Number.isSafeInteger(Number.parseInt(siteId))) {
      throw new Error(`Provided site id "${siteId}" is invalid.`);
    }

    if (!companyId || !Number.isSafeInteger(Number.parseInt(companyId))) {
      throw new Error(`Provided company id "${companyId}" is invalid.`);
    }

    if (!deviceId || !Number.isSafeInteger(Number.parseInt(deviceId))) {
      throw new Error(`Provided device id "${deviceId}" is invalid.`);
    }

    const deviceDetailsPromise = queryClient.fetchQuery(
      deviceDetailsQuery(Number.parseInt(siteId), Number.parseInt(deviceId))
    );
    const siteDetailsPromise = queryClient.fetchQuery(siteDetailsQuery(Number.parseInt(siteId)));

    const [deviceDetails, siteDetails] = await Promise.all([deviceDetailsPromise, siteDetailsPromise]);

    if (siteDetails.company.id !== Number.parseInt(companyId || '')) {
      throw new Error(`Site with id ${siteId} does not exist in company with id ${companyId}`);
    }

    return { siteDetails, deviceDetails, deviceId: Number.parseInt(deviceId) };
  };
