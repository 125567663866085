export const Stage = {
  prospect: '1 - Prospect',
  input_sheet: '2 - Input Sheet',
  model_sent: '3 - Model Sent',
  term_sheet_sent: '4 - Term Sheet',
  term_sheet_signed: '5 - Term Sheet Signed',
  diligence: '6 - Diligence'
};

export const OwnershipStructure = {
  tax_equity_only: 'Tax Equity Only'
};

export const State = {
  AK: 'AK',
  AL: 'AL',
  AR: 'AR',
  AZ: 'AZ',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DC: 'DC',
  DE: 'DE',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  IA: 'IA',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  MA: 'MA',
  MD: 'MD',
  ME: 'ME',
  MI: 'MI',
  MN: 'MN',
  MO: 'MO',
  MS: 'MS',
  MT: 'MT',
  NC: 'NC',
  ND: 'ND',
  NE: 'NE',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NV: 'NV',
  NY: 'NY',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VA: 'VA',
  VT: 'VT',
  WA: 'WA',
  WI: 'WI',
  WV: 'WV',
  WY: 'WY'
};

export const DAS = {
  also_energy: 'Also Energy',
  chint_monitoring_system: 'Chint Monitoring System',
  locus_noc: 'LocusNOC',
  mana_monitoring_system: 'Mana Monitoring System',
  solarlog: 'Solarlog',
  solarenview: 'Solarenview',
  sunny_portal: 'Sunny Portal'
};
