import { QueryClient, queryOptions } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { ApiClient } from '../../../../api';

export const siteDetailsQuery = (siteId: number, enabled = true, throwOnError = false) =>
  queryOptions({
    queryKey: ['site', 'details', { siteId }],
    queryFn: () => ApiClient.assetManagement.getSiteById(siteId),
    enabled: enabled,
    throwOnError: throwOnError ? true : undefined
  });

export const documentInfoQuery = (siteId: number, documentId: number, enabled = true, throwOnError = false) =>
  queryOptions({
    queryKey: ['documents', 'info', { siteId, documentId }],
    queryFn: () => ApiClient.dueDiligence.docInfo(siteId, documentId),
    enabled: enabled,
    throwOnError: throwOnError ? true : undefined
  });

export const createLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const siteId = params.siteId;
    const companyId = params.companyId;
    const documentId = params.documentId;

    const isValidId = !!siteId && Number.isSafeInteger(Number.parseInt(siteId));
    if (!isValidId) throw new Error(`Provided site id "${siteId}" is invalid.`);

    const isValidDocumentId = !!documentId && Number.isSafeInteger(Number.parseInt(documentId));
    if (!isValidDocumentId) throw new Error(`Provided document id "${documentId}" is invalid.`);

    const [siteData, documentInfo] = await Promise.all([
      queryClient.fetchQuery(siteDetailsQuery(Number.parseInt(siteId))),
      queryClient.fetchQuery(documentInfoQuery(Number.parseInt(siteId), Number.parseInt(documentId)))
    ]);
    if (siteData.company.id !== Number.parseInt(companyId || '')) {
      throw new Error(`Site with id ${siteId} does not exist in company with id ${companyId}`);
    }

    return { siteData, documentInfo };
  };
