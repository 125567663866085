import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { WidgetContainer } from '../../Overview.style';
import { formatFloatValue } from '../../../../../../../../utils/formatters/formatFloatValue';

interface LossesProps {
  title: string;
  data?: {
    cumulative: number;
    expected: number;
    loss: number;
    curtailment: number;
    downtime: number;
    snow: number;
    soiling: number;
    unclassified: number;
  };
}

const Losses: React.FC<LossesProps> = ({ title, data }) => {
  if (!data) return null;

  const colorMap: any = {
    Curtailment: '#FAE353',
    Downtime: '#86D0FD',
    Snow: '#8CD88A',
    Soiling: '#673AB7',
    Unclassified: '#D2D4DA'
  };
  const { cumulative, expected, loss, curtailment, downtime, snow, soiling, unclassified } = data;

  const chartData = [
    { asset: 'Curtailment', amount: curtailment },
    { asset: 'Downtime', amount: downtime },
    { asset: 'Snow', amount: snow },
    { asset: 'Soiling', amount: soiling },
    { asset: 'Unclassified', amount: unclassified }
  ];

  const fills = chartData.map(item => colorMap[item.asset] || '#000000');
  const isPlaceholder = cumulative === expected || cumulative > expected;

  const options: AgChartOptions = {
    autoSize: true,
    height: 350,
    data: chartData,
    series: [
      {
        type: 'donut',
        calloutLabelKey: 'asset',
        calloutLabel: {
          enabled: false
        },
        fills: fills,
        angleKey: 'amount',
        innerRadiusRatio: 0.5
      }
    ],
    legend: {
      maxHeight: 70,
      item: {
        marker: {
          shape: 'circle'
        }
      }
    }
  };

  return (
    <WidgetContainer>
      <Typography variant="h6" mb="6px">
        {title}
      </Typography>
      <Box display="flex" flexDirection="row" flexGrow={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            {isPlaceholder ? (
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize={20}
                lineHeight="32px"
                textAlign="center"
                marginY="70px"
              >
                No Losses Today
              </Typography>
            ) : (
              <AgChartsReact options={options} />
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '32px' }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight={500} fontSize={20} lineHeight="32px">
                    {formatFloatValue(cumulative)}
                  </Typography>
                  <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                    Cumulative for a Day, kWh
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" fontWeight={500} fontSize={20} lineHeight="32px">
                    {formatFloatValue(expected)}
                  </Typography>
                  <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                    Expected for a Day, kWh
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline',
                      padding: '8px 16px',
                      backgroundColor: theme => theme.palette.background.default
                    }}
                  >
                    <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                      Energy Loss:
                    </Typography>
                    <Typography variant="h6" fontWeight={500} fontSize={20} lineHeight="32px" mx={0.5}>
                      {formatFloatValue(loss)}
                    </Typography>
                    <Typography variant="caption" color={theme => theme.palette.text.secondary}>
                      kWh
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WidgetContainer>
  );
};

export default Losses;
