import React from 'react';
import Typography from '@mui/material/Typography';
import NewComment from './NewComment';
import CommentsList from './CommentsList';

interface TaskCommentsProps {
  taskId: number;
  boardId: number;
}

export const TaskComments: React.FC<TaskCommentsProps> = ({ taskId, boardId }) => {
  return (
    <>
      <Typography variant="h6" fontSize="16px" mb="12px" fontWeight="600">
        Comments
      </Typography>
      <NewComment taskId={taskId} boardId={boardId} />
      <CommentsList taskId={taskId} />
    </>
  );
};

export default TaskComments;
