import { QueryClient } from '@tanstack/react-query';
import { RouteHandle } from '../../../../handles';
import { createCompanyDetailsLoader } from './loader';

export const createCompanyDetailsHandle = (queryClient: QueryClient) => {
  const crumbsBuilder = (data: any) => {
    if (typeof data?.actual_production_section.id !== 'number') {
      return [];
    }
    const companyDetails = queryClient.getQueryData<Awaited<ReturnType<ReturnType<typeof createCompanyDetailsLoader>>>>(
      ['company', 'details', { companyId: data.actual_production_section.id }]
    );

    return companyDetails
      ? [{ title: 'Companies', link: '/operations-and-maintenance' }, { title: companyDetails.name }]
      : [{ title: 'Companies', link: '/operations-and-maintenance' }, { title: '...' }];
  };

  return RouteHandle.createHandle({
    crumbsBuilder: crumbsBuilder,
    moduleId: 'operations-and-maintenance'
  });
};
