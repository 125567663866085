import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { WidgetContainer } from '../../Overview.style';

interface DevicesProps {
  title: string;
  data?: {
    critical_errors: number;
    device_type: string;
    devices: number;
    no_respond: number;
  }[];
}

const Devices: React.FC<DevicesProps> = ({ title, data }) => {
  if (!data) return null;

  return (
    <WidgetContainer>
      <Typography variant="h6" mb="6px">
        {title}
      </Typography>
      <Table sx={{ width: '100%' }} size="small">
        <TableBody>
          <TableRow sx={{ '& .MuiTableCell-root': { px: 0, pb: 0, pt: 1, border: 'none' } }}>
            <TableCell component="th"></TableCell>
            <TableCell component="th" sx={{ color: theme => theme.palette.text.secondary }}>
              Devices
            </TableCell>
            <TableCell component="th" sx={{ color: theme => theme.palette.text.secondary }}>
              Critical Errors
            </TableCell>
            <TableCell component="th" sx={{ color: theme => theme.palette.text.secondary }}>
              No Respond
            </TableCell>
          </TableRow>
          {data.map(({ critical_errors, device_type, devices, no_respond }) => (
            <TableRow key={device_type} sx={{ '& .MuiTableCell-root': { px: 0, pb: 0, pt: 1, border: 'none' } }}>
              <TableCell component="th" sx={{ textTransform: 'capitalize' }}>
                {device_type}
              </TableCell>
              <TableCell component="th">{devices}</TableCell>
              <TableCell component="th">{critical_errors}</TableCell>
              <TableCell component="th">{no_respond}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </WidgetContainer>
  );
};

export default Devices;
