import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { WidgetContainer } from '../../Overview.style';

interface InvertersPerformanceProps {
  title: string;
  data?: {
    name: string;
    performance: number;
  }[];
}

interface ItemProps {
  color: string;
}

const Item = styled(Box)<ItemProps>(({ theme, color }) => ({
  width: 65,
  height: 48,
  fontSize: '12px',
  color: theme.palette.text.primary,
  backgroundColor: color,
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(0.5),
  animation: 'grow 0.5s ease-out',
  '@keyframes grow': {
    '0%': {
      transform: 'scale(0.5)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  }
}));

export const InvertersPerformance: React.FC<InvertersPerformanceProps> = ({ title, data }) => {
  const theme = useTheme();
  if (!data) return null;

  const derivePerformanceColorFromValue = (value: number): string => {
    if (value === 0) return theme.efficiencyColors.none;
    if (value < 51) return theme.efficiencyColors.low;
    if (value < 90) return theme.efficiencyColors.mediocre;
    if (value < 101) return theme.efficiencyColors.good;
    return theme.efficiencyColors.outstanding;
  };

  return (
    <WidgetContainer>
      <Typography variant="h6" mb="6px">
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'left',
          paddingY: theme.spacing(1),
          gap: '10px'
        }}
      >
        {data.map(({ name, performance }) => (
          <Item key={name} color={derivePerformanceColorFromValue(performance)}>
            <Typography
              variant="body2"
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                display: 'block',
                marginTop: '2px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: '12px',
                fontWeight: 500,
                display: 'block',
                marginTop: '2px'
              }}
            >
              {performance}%
            </Typography>
          </Item>
        ))}
      </Box>
    </WidgetContainer>
  );
};

export default InvertersPerformance;
