import React from 'react';
import SunnyIcon from '@mui/icons-material/WbSunny';
import CloudIcon from '@mui/icons-material/Cloud';
import Box from '@mui/material/Box';

interface WeatherIndicatorProps {
  value: 'Sunny' | 'Cloudy' | 'Partly cloudy';
}

export const WeatherIndicator: React.FC<WeatherIndicatorProps> = ({ value }) => (
  <Box
    position="relative"
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    data-testid="weather-indicator__component"
  >
    {value === 'Sunny' && <SunnyIcon sx={{ color: '#FAE353' }} />}
    {value === 'Cloudy' && <CloudIcon sx={{ color: '#D2D4DA' }} />}
    {value === 'Partly cloudy' && (
      <>
        <SunnyIcon sx={{ position: 'relative', left: '7px', top: '-4px', color: '#FAE353' }} />
        <CloudIcon sx={{ position: 'relative', right: '7px', color: '#D2D4DA' }} />
      </>
    )}
  </Box>
);

export default WeatherIndicator;
