import React from 'react';

import { AssetManagementSiteDetailsTabProps } from '../types';
import TasksCluster from '../../../../components/clusters/TasksCluster/TasksCluster';

export const Tasks: React.FC<AssetManagementSiteDetailsTabProps> = ({ siteDetails }) => (
  <TasksCluster scope="site" companyId={siteDetails.company.id} siteId={siteDetails.id} />
);

export default Tasks;
