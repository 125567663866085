import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

import { FileItem } from '../../../api';
import { DocImageRenderer } from './DocImageRenderer';

dayjs.extend(utc);

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export const DocumentContainer = styled(Box)(({ theme }) => ({
  minHeight: '650px',
  width: '652px',
  margin: '0 auto',
  '#proxy-renderer': {
    overflow: 'hidden'
  },
  '#pdf-controls': {
    position: 'absolute',
    width: '100%',
    height: '56px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: 'none',
    padding: '8px 20px',
    borderBottom: '1px solid #E0E0E0',
    background: theme.palette.common.white,
    '& > *': {
      color: theme.palette.text.secondary,
      boxShadow: 'none',
      margin: '0px 8px'
    },
    path: {
      fill: theme.palette.text.secondary
    },
    polygon: {
      fill: theme.palette.text.secondary
    }
  },
  '#pdf-pagination': {
    order: 1,
    margin: 0,
    '& > *': {
      color: theme.palette.text.secondary,
      boxShadow: 'none'
    }
  },
  '#pdf-download': {
    display: 'none'
  },
  '#msdoc-renderer': {
    marginTop: '-1px',
    marginLeft: '-1px'
  }
}));

interface DocumentModal {
  open: boolean;
  fileUrl: string;
  file: FileItem | null;
  onClose: () => void;
}

const DocumentModal: React.FC<DocumentModal> = props => {
  const { open, file, fileUrl, onClose } = props;

  if (!file || !fileUrl) return null;

  return (
    <BootstrapDialog maxWidth={`lg`} onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle sx={{ bgcolor: 'primary.main', color: 'secondary.main' }} id="customized-dialog-title">
        {file.filename}
        <Typography variant="body2" sx={{ marginTop: '5px' }}>
          Uploaded by {file.author}, {dayjs.utc(file.created_at).local().format('lll')}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.secondary.main
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{
          backgroundColor: theme => theme.palette.background.default,
          width: '964px',
          position: 'relative',
          padding:
            file.filename.endsWith('.pdf') ||
            file.filename.endsWith('.jpg') ||
            file.filename.endsWith('.jpeg') ||
            file.filename.endsWith('.png')
              ? '70px 16px 16px!important'
              : '16px'
        }}
      >
        {document && (
          <DocumentContainer>
            <DocViewer
              pluginRenderers={[DocImageRenderer, ...DocViewerRenderers]}
              documents={[{ uri: fileUrl }]}
              style={{ width: 650, height: 650 }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true
                },
                pdfVerticalScrollByDefault: true
              }}
            />
          </DocumentContainer>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DocumentModal;
