import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
// Providers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { BaseLayout } from './components/layout/BaseLayout/BaseLayout';
import { AuthLayout } from './components/layout/AuthLayout/AuthLayout';
import { routeHandlesMap } from './handles';
import theme from './utils/styles/theme';
import { AuthProvider, useAuth } from './contexts/auth/auth';
import { NotificationsProvider } from './contexts/notifications/notifications';
import { ActionProcessorsProvider } from './contexts/action-processor/action-processor';
import { withAuthControl } from './utils/loaders';
import Settings from './pages/Settings/Settings';
import Login from './pages/Login/Login';
import PasswordResetRequest from './pages/PasswordResetRequest/PasswordResetRequest';
import PasswordResetNotification from './pages/PasswordResetNotification/PasswordResetNotification';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import PasswordResetSuccess from './pages/PasswordResetSuccess/PasswordResetSuccess';
import PasswordResetInvalid from './pages/PasswordResetInvalid/PasswordResetInvalid';
import AssetManagement from './pages/AssetManagement/AssetManagement';
import AssetManagementCompanyDetails, {
  createAssetManagementCompanyDetailsLoader,
  createAssetManagementCompanyDetailsHandle
} from './pages/AssetManagementCompanyDetails';
import AssetManagementSiteDetails, {
  createAssetManagementSiteDetailsLoader,
  createAssetManagementSiteDetailsHandle
} from './pages/AssetManagementSiteDetails';
import AssetManagementAddDevice, { createAssetManagementAddDeviceHandle } from './pages/AssetManagementAddDevice';
import DeviceDetails, {
  createAssetManagementDeviceDetailsLoader,
  createAssetManagementDeviceDetailsHandle
} from './modules/asset-management/pages/DeviceDetails';
import AddUser from './pages/AddUser/AddUser';
import AddCompany from './pages/AddCompany/AddCompany';
import EditCompany from './pages/EditCompany/EditCompany';
import SignUp from './pages/SignUp/SignUp';
import AddSite from './pages/AddSite/AddSite';
import EditUser from './pages/EditUser/EditUser';
import EditSite from './pages/EditSite/EditSite';
import MyCompany from './pages/MyCompany/MyCompany';
import EditMyCompany from './pages/EditMyCompany/EditMyCompany';
import MyCompanyEditSite from './pages/MyCompanyEditSite/MyCompanyEditSite';
import Index from './pages/Index/Index';
import AddMyCompanyUser from './pages/AddMyCompanyUser/AddMyCompanyUser';
import EditMyCompanyUser from './pages/EditMyCompanyUser/EditMyCompanyUserPage';
import { SiteTask as AMSiteTask, CompanyTask as AMCompanyTask } from './modules/asset-management';
import {
  AllCompanies as OMAllCompanies,
  CompanyDetails as OMCompanyDetails,
  SiteDetails as OMSiteDetails,
  DeviceDetails as OMDeviceDetails,
  createCompanyDetailsHandle,
  createCompanyDetailsLoader,
  createSiteDetailsHandle,
  createSiteDetailsLoader,
  createDeviceDetailsHandle,
  createDeviceDetailsLoader
} from './modules/operations-and-maintenance';

import { DashboardPage as DPDashboardPage } from './modules/dashboard';
import {
  DueDiligencePage as DPDiligencePage,
  SitesPage as DPSitesPage,
  SitePage as DPSitePage,
  DueDiligenceDocument as DPDueDiligenceDocument
} from './modules/due-diligence';
import { ErrorLayout } from './components/layout/ErrorLayout/ErrorLayout';

// initialization
const queryClient = new QueryClient();

const AdminType = {
  system: 'system_user',
  full: 'company_admin_full',
  view: 'company_view'
};

type ProtectedRouteProps = {
  element: React.ReactElement;
  permission: string[];
  path?: string;
};

const ProtectedSettingsRoute = ({ element, permission, path }: ProtectedRouteProps) => {
  const { isAuthenticated, user } = useAuth();
  const access = user?.settings_access;

  if (isAuthenticated && access) {
    const isValid = permission.some(perm => access.includes(perm));
    if (isValid) {
      return element;
    } else if (
      path === '/settings' &&
      (user?.settings_access === 'company_admin_full' || user?.settings_access === 'company_view')
    ) {
      return <Navigate to="/settings/my-company" replace />;
    }
  }

  return <Navigate to="/" replace />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<BaseLayout />} errorElement={<ErrorLayout />}>
        <Route index element={<Index />} />
        <Route path="/dashboard">
          <Route index handle={DPDashboardPage.createHandle()} element={<DPDashboardPage.Component />} />
        </Route>
        <Route path="/due-diligence">
          <Route index handle={DPDiligencePage.createHandle()} element={<DPDiligencePage.Component />} />
          <Route
            path="/due-diligence/companies/:companyId/sites"
            handle={DPSitesPage.createHandle(queryClient)}
            loader={DPSitesPage.createLoader(queryClient)}
            element={<DPSitesPage.Component />}
          />
          <Route
            path="/due-diligence/companies/:companyId/sites/:siteId"
            handle={DPSitePage.createHandle()}
            loader={DPSitePage.createLoader(queryClient)}
            element={<DPSitePage.Component />}
          />
          <Route
            path="/due-diligence/companies/:companyId/sites/:siteId/overview"
            handle={DPSitePage.createHandle()}
            loader={DPSitePage.createLoader(queryClient)}
            element={<DPSitePage.Component tabId="overview" />}
          />
          <Route
            path="/due-diligence/companies/:companyId/sites/:siteId/due-diligence"
            handle={DPSitePage.createHandle()}
            loader={DPSitePage.createLoader(queryClient)}
            element={<DPSitePage.Component tabId="diligence" />}
          />
          <Route
            path="/due-diligence/companies/:companyId/sites/:siteId/due-diligence/:documentId"
            handle={DPDueDiligenceDocument.createHandle(queryClient)}
            loader={withAuthControl(DPDueDiligenceDocument.createLoader(queryClient))}
            element={<DPDueDiligenceDocument.Component />}
          />
        </Route>
        <Route path="/operations-and-maintenance">
          <Route index handle={OMAllCompanies.createHandle()} element={<OMAllCompanies.Component />} />
          <Route path="companies" handle={OMAllCompanies.createHandle()} element={<OMAllCompanies.Component />} />
          <Route
            path="companies/:companyId"
            handle={createCompanyDetailsHandle(queryClient)}
            loader={withAuthControl(createCompanyDetailsLoader(queryClient))}
            element={<OMCompanyDetails />}
          />
          <Route
            path="companies/:companyId/overview"
            handle={createCompanyDetailsHandle(queryClient)}
            loader={withAuthControl(createCompanyDetailsLoader(queryClient))}
            element={<OMCompanyDetails tabId="overview" />}
          />
          <Route
            path="companies/:companyId/sites"
            handle={createCompanyDetailsHandle(queryClient)}
            loader={withAuthControl(createCompanyDetailsLoader(queryClient))}
            element={<OMCompanyDetails tabId="sites" />}
          />
          <Route
            path="companies/:companyId/alerts"
            handle={createCompanyDetailsHandle(queryClient)}
            loader={withAuthControl(createCompanyDetailsLoader(queryClient))}
            element={<OMCompanyDetails tabId="alerts" />}
          />
          <Route
            path="companies/:companyId/sites/:siteId"
            handle={createSiteDetailsHandle()}
            loader={withAuthControl(createSiteDetailsLoader(queryClient))}
            element={<OMSiteDetails />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/overview"
            handle={createSiteDetailsHandle()}
            loader={withAuthControl(createSiteDetailsLoader(queryClient))}
            element={<OMSiteDetails tabId="overview" />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/devices"
            handle={createSiteDetailsHandle()}
            loader={withAuthControl(createSiteDetailsLoader(queryClient))}
            element={<OMSiteDetails tabId="devices" />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/alerts"
            handle={createSiteDetailsHandle()}
            loader={withAuthControl(createSiteDetailsLoader(queryClient))}
            element={<OMSiteDetails tabId="alerts" />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/security"
            handle={createSiteDetailsHandle()}
            loader={withAuthControl(createSiteDetailsLoader(queryClient))}
            element={<OMSiteDetails tabId="security" />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/device/:deviceId"
            handle={createDeviceDetailsHandle()}
            loader={withAuthControl(createDeviceDetailsLoader(queryClient))}
            element={<OMDeviceDetails />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/device/:deviceId/overview"
            handle={createDeviceDetailsHandle()}
            loader={withAuthControl(createDeviceDetailsLoader(queryClient))}
            element={<OMDeviceDetails tabId="overview" />}
          />
          <Route
            path="companies/:companyId/sites/:siteId/device/:deviceId/alerts"
            handle={createDeviceDetailsHandle()}
            loader={withAuthControl(createDeviceDetailsLoader(queryClient))}
            element={<OMDeviceDetails tabId="alerts" />}
          />
        </Route>
        <Route path="/asset-management" handle={routeHandlesMap['/asset-management']} element={<AssetManagement />} />
        <Route
          path="/asset-management/overview"
          handle={routeHandlesMap['/asset-management']}
          element={<AssetManagement tabId="overview" />}
        />
        <Route
          path="/asset-management/sites"
          handle={routeHandlesMap['/asset-management']}
          element={<AssetManagement tabId="sites" />}
        />
        <Route
          path="/asset-management/companies/:companyId"
          handle={createAssetManagementCompanyDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementCompanyDetailsLoader(queryClient))}
          element={<AssetManagementCompanyDetails />}
        />
        <Route
          path="/asset-management/companies/:companyId/overview"
          handle={createAssetManagementCompanyDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementCompanyDetailsLoader(queryClient))}
          element={<AssetManagementCompanyDetails tabId="overview" />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites"
          handle={createAssetManagementCompanyDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementCompanyDetailsLoader(queryClient))}
          element={<AssetManagementCompanyDetails tabId="sites" />}
        />
        <Route
          path="/asset-management/companies/:companyId/tasks"
          handle={createAssetManagementCompanyDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementCompanyDetailsLoader(queryClient))}
          element={<AssetManagementCompanyDetails tabId="tasks" />}
        />
        <Route
          path="/asset-management/companies/:companyId/tasks/:taskId"
          handle={AMCompanyTask.createHandle(queryClient)}
          loader={withAuthControl(AMCompanyTask.createLoader(queryClient))}
          element={<AMCompanyTask.Component />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId"
          handle={createAssetManagementSiteDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementSiteDetailsLoader(queryClient))}
          element={<AssetManagementSiteDetails />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/overview"
          handle={createAssetManagementSiteDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementSiteDetailsLoader(queryClient))}
          element={<AssetManagementSiteDetails tabId="overview" />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/devices"
          handle={createAssetManagementSiteDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementSiteDetailsLoader(queryClient))}
          element={<AssetManagementSiteDetails tabId="devices" />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/tasks"
          handle={createAssetManagementSiteDetailsHandle(queryClient)}
          loader={withAuthControl(createAssetManagementSiteDetailsLoader(queryClient))}
          element={<AssetManagementSiteDetails tabId="tasks" />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/devices/add"
          loader={withAuthControl(createAssetManagementSiteDetailsLoader(queryClient))}
          handle={createAssetManagementAddDeviceHandle(queryClient)}
          element={<AssetManagementAddDevice />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/devices/:deviceId"
          loader={withAuthControl(createAssetManagementDeviceDetailsLoader(queryClient))}
          handle={createAssetManagementDeviceDetailsHandle()}
          element={<DeviceDetails />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/devices/:deviceId/overview"
          handle={createAssetManagementDeviceDetailsHandle()}
          loader={withAuthControl(createAssetManagementDeviceDetailsLoader(queryClient))}
          element={<DeviceDetails tabId="overview" />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/devices/:deviceId/tasks"
          handle={createAssetManagementDeviceDetailsHandle()}
          loader={withAuthControl(createAssetManagementDeviceDetailsLoader(queryClient))}
          element={<DeviceDetails tabId="tasks" />}
        />
        <Route
          path="/asset-management/companies/:companyId/sites/:siteId/tasks/:taskId"
          handle={AMSiteTask.createHandle(queryClient)}
          loader={withAuthControl(AMSiteTask.createLoader(queryClient))}
          element={<AMSiteTask.Component />}
        />
        <Route
          path="/settings"
          element={<ProtectedSettingsRoute element={<Settings />} permission={[AdminType.system]} path="/settings" />}
        />
        <Route
          path="/settings/users"
          element={<ProtectedSettingsRoute element={<Settings tabId="users" />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/companies"
          element={<ProtectedSettingsRoute element={<Settings tabId="companies" />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/sites"
          element={<ProtectedSettingsRoute element={<Settings tabId="sites" />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/audit-logs"
          element={<ProtectedSettingsRoute element={<Settings tabId="audit-logs" />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/users/add"
          handle={routeHandlesMap['/settings/users/add']}
          element={<ProtectedSettingsRoute element={<AddUser />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/users/:id/edit"
          handle={routeHandlesMap['/settings/users/{id}/edit']}
          element={<ProtectedSettingsRoute element={<EditUser />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/company/add"
          handle={routeHandlesMap['/settings/company/add']}
          element={<ProtectedSettingsRoute element={<AddCompany />} permission={[AdminType.system]} />}
        />
        <Route
          path="/settings/company/:companyId"
          handle={routeHandlesMap['/settings/company/:companyId']}
          element={<ProtectedSettingsRoute element={<EditCompany />} permission={[AdminType.system, AdminType.full]} />}
        />
        <Route
          path="/settings/company/:companyId/site/add"
          element={<ProtectedSettingsRoute element={<AddSite />} permission={[AdminType.system, AdminType.full]} />}
        />
        <Route
          path="/settings/company/:companyId/site/:siteId/edit"
          handle={routeHandlesMap['/settings/company/:companyId/site/:siteId/edit']}
          element={<ProtectedSettingsRoute element={<EditSite />} permission={[AdminType.system, AdminType.full]} />}
        />
        <Route
          path="/settings/my-company"
          handle={routeHandlesMap['/settings/my-company']}
          element={<ProtectedSettingsRoute element={<MyCompany />} permission={[AdminType.full, AdminType.view]} />}
        />
        <Route
          path="/settings/my-company/edit"
          handle={routeHandlesMap['/settings/my-company/edit']}
          element={<ProtectedSettingsRoute element={<EditMyCompany />} permission={[AdminType.full, AdminType.view]} />}
        />
        <Route
          path="/settings/my-company/overview"
          handle={routeHandlesMap['/settings/my-company/overview']}
          element={
            <ProtectedSettingsRoute
              element={<MyCompany tabId="overview" />}
              permission={[AdminType.full, AdminType.view]}
            />
          }
        />
        <Route
          path="/settings/my-company/sites"
          handle={routeHandlesMap['/settings/my-company/sites']}
          element={
            <ProtectedSettingsRoute
              element={<MyCompany tabId="sites" />}
              permission={[AdminType.full, AdminType.view]}
            />
          }
        />
        <Route
          path="/settings/my-company/site/:siteId/edit"
          handle={routeHandlesMap['/settings/my-company/site/:siteId/edit']}
          element={
            <ProtectedSettingsRoute element={<MyCompanyEditSite />} permission={[AdminType.full, AdminType.view]} />
          }
        />
        <Route
          path="/settings/my-company/users"
          handle={routeHandlesMap['/settings/my-company/users']}
          element={
            <ProtectedSettingsRoute
              element={<MyCompany tabId="users" />}
              permission={[AdminType.full, AdminType.view]}
            />
          }
        />
        <Route
          path="/settings/my-company/users/:userId/edit"
          handle={routeHandlesMap['/settings/my-company/users/:userId/edit']}
          element={
            <ProtectedSettingsRoute element={<EditMyCompanyUser />} permission={[AdminType.full, AdminType.view]} />
          }
        />
        <Route
          path="/settings/my-company/users/add"
          handle={routeHandlesMap['/settings/my-company/users/add']}
          element={
            <ProtectedSettingsRoute element={<AddMyCompanyUser />} permission={[AdminType.full, AdminType.view]} />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<PasswordResetRequest />} />
        <Route path="/reset-notification" element={<PasswordResetNotification />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
        <Route path="/password-reset-invalid" element={<PasswordResetInvalid />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Route>
    </Route>
  )
);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationsProvider>
              <ActionProcessorsProvider>
                <RouterProvider router={router} />
              </ActionProcessorsProvider>
            </NotificationsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
