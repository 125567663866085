import React from 'react';

import { AssetManagementCompanyDetailsTabProps } from '../types';
import TasksCluster from '../../../../components/clusters/TasksCluster/TasksCluster';

export const Tasks: React.FC<AssetManagementCompanyDetailsTabProps> = ({ companyDetails }) => (
  <TasksCluster scope="company" companyId={companyDetails.id} />
);

export default Tasks;
