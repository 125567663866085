import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import Assignee from '../Assignee/Assignee';
import { DiligenceDocument } from '../../../../../../../../api';

interface DocumentItemProps {
  document: DiligenceDocument;
}

const DocumentItem: React.FC<DocumentItemProps> = ({ document }) => {
  const navigate = useNavigate();
  const { siteId, companyId } = useParams();

  const filesCount = (item: number) => {
    switch (item) {
      case 0:
        return 'No Files Yet';
      case 1:
        return '1 File';
      default:
        return `${item} Files`;
    }
  };

  return (
    <Box
      data-testid="document-item__component"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        minHeight: '80px',
        borderBottom: '1px solid #E0E0E0',
        '&:last-child': {
          borderBottom: 0
        }
      }}
    >
      <Box width="40%" ml="auto">
        {document.name}
      </Box>
      <Box width="15%">{filesCount(document.files_count)}</Box>
      <Box width="40%" minWidth="265px" height="100%" mr="auto">
        <Grid container spacing={2} justifyContent="flex-start" alignItems="center" wrap="nowrap">
          <Grid item xs={4} ml="auto">
            {document.status && (
              <Chip
                label={document.status}
                size="small"
                sx={theme => ({
                  color: theme.palette.primary.main,
                  background: '#F4E998',
                  minWidth: '75px'
                })}
              />
            )}
          </Grid>
          <Grid item mr="auto">
            <Assignee user={document.assignee} />
          </Grid>
          <Grid item ml="auto">
            <Button
              variant="outlined"
              sx={{ width: '120px', height: '32px', padding: '0', fontWeight: '500' }}
              onClick={() => {
                navigate(`/due-diligence/companies/${companyId}/sites/${siteId}/due-diligence/${document.id}`);
              }}
            >
              Open
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DocumentItem;
