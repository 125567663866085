import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ApiClient, CreateSiteAttributes, SiteDetailedInfo } from '../../../api';
import { State, DAS } from '../../../utils/asset-managment';
import { useNotify } from '../../../contexts/notifications/notifications';
import { FormattedNumericInput } from '../../common/FormattedNumericInput/FormattedNumericInput';
import formatNumericValue from '../../../utils/formatters/formatFloatValue';
import CircularProgress from '@mui/material/CircularProgress';
import FilledInput from '@mui/material/FilledInput';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { AxiosError } from 'axios';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';

const noBottomLineStyles = {
  '& .MuiInputBase-root:not(.Mui-disabled, .Mui-error)': {
    '&::before, &:hover::before, &.Mui-focused::after': {
      borderBottomColor: 'transparent',
      transform: 'scaleX(0)'
    }
  }
};

type SiteFormFields = {
  company_id?: number;
  name: string;
  address: string;
  city: string;
  state: string;
  county?: string;
  zip_code: string;
  system_size_ac: string;
  system_size_dc: string;
  das: string;
  lon_lat_url: string;
  cameras_uuids: string[];
};

type SiteFormProps =
  | { mode: 'add'; siteId?: number; siteData?: SiteDetailedInfo; companyId?: number }
  | { mode: 'edit'; siteId: number; siteData: SiteDetailedInfo; companyId?: number };

export const SiteForm: React.FC<SiteFormProps> = props => {
  const { companyId, mode, siteData } = props;
  const [loading, setLoading] = useState(false);
  const { siteId } = useParams();
  const { mutateAsync } = useMutation({
    mutationFn: async (attributes: CreateSiteAttributes) => {
      setLoading(true);
      try {
        if (siteId) {
          return await ApiClient.assetManagement.updateSite(siteId, attributes);
        } else {
          return await ApiClient.assetManagement.createSite(attributes);
        }
      } finally {
        setLoading(false);
      }
    }
  });
  const isEdit = mode === 'edit';
  const navigate = useNavigate();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const {
    data: cameraData,
    isLoading: isLoadingCameraData,
    error: cameraError
  } = useQuery({
    queryFn: async () => {
      return ApiClient.security.getSecurityCameras();
    },
    queryKey: ['security-cameras']
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitSuccessful, isSubmitted, isDirty },
    setError,
    control,
    clearErrors
  } = useForm<SiteFormFields>({
    mode: 'onBlur',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...(isEdit && siteData
        ? {
            name: siteData.name,
            address: siteData.address,
            city: siteData.city,
            state: siteData.state,
            county: siteData.county,
            zip_code: siteData.zip_code,
            system_size_ac: formatNumericValue(siteData.system_size_ac, true),
            system_size_dc: formatNumericValue(siteData.system_size_dc, true),
            das: siteData.das,
            lon_lat_url: siteData.lon_lat_url,
            cameras_uuids: siteData.cameras_uuids
          }
        : {
            company_id: companyId,
            name: undefined,
            address: undefined,
            city: undefined,
            state: undefined,
            county: undefined,
            zip_code: undefined,
            system_size_ac: undefined,
            system_size_dc: undefined,
            das: undefined,
            lon_lat_url: undefined,
            cameras_uuids: undefined
          })
    }
  });

  const onSubmit: SubmitHandler<SiteFormFields> = async data => {
    try {
      clearErrors('root');
      await mutateAsync({
        ...(data.company_id && { company_id: data.company_id }),
        name: data.name,
        address: data.address,
        city: data.city,
        state: data.state,
        county: data.county,
        zip_code: data.zip_code,
        system_size_ac: Number.parseFloat(data.system_size_ac.replaceAll(',', '')),
        system_size_dc: Number.parseFloat(data.system_size_dc.replaceAll(',', '')),
        das: data.das,
        lon_lat_url: data.lon_lat_url,
        cameras_uuids: data.cameras_uuids
      });
      queryClient.removeQueries({ queryKey: ['site', { siteId }] });
      queryClient.removeQueries({ queryKey: ['my-company-site', { siteId }] });
      queryClient.removeQueries({ queryKey: ['sites'] });
      queryClient.removeQueries({ queryKey: ['camera-alerts'] });
      queryClient.removeQueries({ queryKey: ['cameras'] });
      notify(isEdit ? 'Site has been updated successfully' : 'Site has been successfully created');
      navigate(-1);
    } catch (e: any) {
      setError('root', {
        message: e.response?.data?.message
      });
    }
  };

  React.useEffect(() => {
    if (cameraError && cameraError instanceof AxiosError) notify(cameraError.message);
  }, [cameraError, notify]);

  return (
    <Stack
      component="form"
      noValidate
      width="30%"
      minWidth="320px"
      spacing={2}
      onSubmit={handleSubmit(onSubmit)}
      sx={{ marginBottom: '20px' }}
    >
      <TextField
        variant="filled"
        required
        label="Project Name"
        sx={noBottomLineStyles}
        helperText={errors.name?.message}
        error={!!errors.name}
        {...register('name', {
          required: 'Project Name is required field.'
        })}
      />
      <TextField
        variant="filled"
        required
        label="Site Address"
        sx={noBottomLineStyles}
        helperText={errors.address?.message}
        error={!!errors.address}
        {...register('address', {
          required: 'Site Address is required field.'
        })}
      />
      <TextField
        variant="filled"
        required
        label="City"
        sx={noBottomLineStyles}
        helperText={errors.city?.message}
        error={!!errors.city}
        {...register('city', {
          required: 'City is required field.'
        })}
      />
      <Controller
        name="state"
        control={control}
        rules={{ required: 'State is required field.' }}
        render={({ field }) => (
          <FormControl error={!!errors.state} variant="filled" required sx={noBottomLineStyles}>
            <InputLabel error={!!errors.state}>State</InputLabel>
            <Select
              ref={field.ref}
              value={field.value}
              error={!!errors.state}
              label="state"
              onBlur={field.onBlur}
              onChange={field.onChange}
            >
              {Object.entries(State).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errors.state?.message && <FormHelperText error>{errors.state.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <TextField
        variant="filled"
        label="County"
        sx={noBottomLineStyles}
        helperText={errors.county?.message}
        error={!!errors.county}
        {...register('county', {})}
      />
      <TextField
        variant="filled"
        required
        label="Zip Code"
        sx={noBottomLineStyles}
        helperText={errors.zip_code?.message}
        error={!!errors.zip_code}
        inputProps={{
          maxLength: 5
        }}
        {...register('zip_code', {
          required: 'Zip Code is required field.',
          minLength: {
            value: 4,
            message: 'Please use a valid Zip Code not less 4 numbers'
          },
          maxLength: {
            value: 5,
            message: 'Zip Code must not exceed 5 characters'
          },
          pattern: {
            value: /^[0-9]*$/,
            message: 'Zip Code should consist only from numbers'
          }
        })}
      />
      <Controller
        name="system_size_ac"
        control={control}
        rules={{
          required: 'System Size AC is required field.',
          validate: value => {
            const withoutThousandSeparators = (value ?? '').replaceAll(',', '');
            return Number.isNaN(Number.parseFloat(withoutThousandSeparators))
              ? 'Invalid number provided as a value for System Size AC'
              : true;
          }
        }}
        render={({ field }) => (
          <TextField
            variant="filled"
            required
            label="System Size kW AC"
            sx={noBottomLineStyles}
            helperText={errors.system_size_ac?.message}
            error={!!errors.system_size_ac}
            name={field.name}
            disabled={field.disabled}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            InputProps={{
              inputComponent: FormattedNumericInput as any,
              ref: field.ref
            }}
          />
        )}
      />
      <Controller
        name="system_size_dc"
        control={control}
        rules={{
          required: 'System Size DC is required field.',
          validate: value => {
            const withoutThousandSeparators = (value ?? '').replaceAll(',', '');
            return Number.isNaN(Number.parseFloat(withoutThousandSeparators))
              ? 'Invalid number provided as a value for System Size DC'
              : true;
          }
        }}
        render={({ field }) => (
          <TextField
            variant="filled"
            required
            label="System Size kW DC"
            sx={noBottomLineStyles}
            helperText={errors.system_size_dc?.message}
            error={!!errors.system_size_dc}
            name={field.name}
            disabled={field.disabled}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            InputProps={{
              inputComponent: FormattedNumericInput as any,
              ref: field.ref
            }}
          />
        )}
      />
      <Controller
        name="das"
        control={control}
        rules={{ required: 'Data Acquisition System Provider is required field.' }}
        render={({ field }) => (
          <FormControl error={!!errors.das} variant="filled" required sx={noBottomLineStyles}>
            <InputLabel error={!!errors.das}>Data Acquisition System Provider</InputLabel>
            <Select
              ref={field.ref}
              value={field.value}
              error={!!errors.das}
              label="Data Acquisition System Provider"
              onBlur={field.onBlur}
              onChange={field.onChange}
            >
              {Object.entries(DAS).map(([key, value]) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {errors.das?.message && <FormHelperText error>{errors.das.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <TextField
        variant="filled"
        required
        label="Latitude/Longitude"
        sx={noBottomLineStyles}
        helperText={
          errors.lon_lat_url?.message ? (
            <span dangerouslySetInnerHTML={{ __html: errors.lon_lat_url.message }} />
          ) : undefined
        }
        error={!!errors.lon_lat_url}
        {...register('lon_lat_url', {
          required: 'Latitude/Longitude is required field.',
          validate: value => {
            const pattern = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/;
            if (!pattern.test(value)) {
              return (
                `Provided value doesn't match the expected format. ` +
                'Latitude/Longitude should be provided as a pair of float numbers, separated by a comma, which represent latitude and longitude in decimal degrees. ' +
                '<br />Example: 34.134078, -118.321695 .'
              );
            }
            const [lat, lon] = value.split(',');
            const numeralLat = Number.parseFloat(lat);
            const numberalLon = Number.parseFloat(lon);

            if (numeralLat < -90 || numeralLat > 90)
              return 'Latitude values in decimal degrees range between -90 and +90.';
            if (numberalLon < -180 || numberalLon > 180)
              return 'Longitude values in decimal degrees range between -180 and +180.';
            return true;
          }
        })}
      />
      <Controller
        name="cameras_uuids"
        control={control}
        disabled={isLoadingCameraData}
        render={({ field }) => {
          const selectedValues = field.value || [];

          const handleDelete = (uuidToDelete: string) => {
            const newValues = selectedValues.filter((uuid: string) => uuid !== uuidToDelete);
            field.onChange(newValues);
          };

          return (
            <FormControl variant="filled" error={!!cameraError} sx={noBottomLineStyles}>
              <InputLabel error={!!cameraError} shrink={selectedValues.length > 0}>
                Security Cameras
              </InputLabel>
              <Select
                ref={field.ref}
                value={selectedValues}
                error={!!cameraError}
                label="cameras_uuids"
                multiple
                disabled={isLoadingCameraData}
                onChange={field.onChange}
                onBlur={field.onBlur}
                input={<FilledInput />}
                IconComponent={isLoadingCameraData ? HourglassBottomRoundedIcon : undefined}
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((uuid: string) => {
                      const option = cameraData?.items.find(opt => opt.uuid === uuid);

                      return (
                        <Chip
                          key={uuid}
                          label={option?.name}
                          onDelete={() => handleDelete(uuid)}
                          onMouseDown={event => event.stopPropagation()}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {cameraData?.items.map(option => (
                  <MenuItem key={option.uuid} value={option.uuid}>
                    <Checkbox checked={selectedValues.indexOf(option.uuid) > -1} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
              </Select>
              {cameraError?.message && <FormHelperText error>{cameraError?.message}</FormHelperText>}
            </FormControl>
          );
        }}
      />
      {errors.root && (
        <Typography px="4px" color="error">
          {errors.root?.message}
        </Typography>
      )}
      {isSubmitted && isSubmitSuccessful && (
        <Typography px="4px" color="green">
          Site was successfully created
        </Typography>
      )}
      <Stack direction="row" width="100%" spacing={3} justifyContent="stretch">
        <Button fullWidth variant="outlined" onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button
          disabled={!isValid || !!errors.root || !isDirty || loading}
          fullWidth
          variant="contained"
          type="submit"
          startIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </Stack>
    </Stack>
  );
};
