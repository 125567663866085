import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { SiteDetailsTabProps } from '../types';
import ActualProduction from './widgets/ActualProduction/ActualProduction';
import PastPerformance from './widgets/PastPerformance/PastPerformance';
import Devices from './widgets/Devices/Devices';
import ActualProjectedPower from './widgets/ActualProjectedPower/ActualProjectedPower';
import InvertersPerformance from './widgets/InvertersPerformance/InvertersPerformance';

export const OverviewTab: React.FC<SiteDetailsTabProps> = ({ siteDetails }) => {
  const actualProduction = {
    title: 'Actual Production',
    data: siteDetails.actual_production_section
  };

  const pastPerformance = {
    title: 'Past Performance',
    data: siteDetails.past_performance_section
  };

  const devices = {
    title: 'Devices',
    data: siteDetails.devices_section
  };

  const actualProjected = {
    title: 'Actual vs Projected Power and Irradiance',
    data: siteDetails.actual_vs_expected_section
  };

  const invertersPerformance = {
    title: 'Inverters Performance',
    data: siteDetails.inverters_performance_section
  };

  return (
    <Box maxWidth="1600px" mx="auto" paddingTop={1} sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={20}>
        <Grid item xs={20} md={10} lg={8}>
          <ActualProduction title={actualProduction.title} data={actualProduction.data} />
        </Grid>
        <Grid item xs={20} md={10} lg={6}>
          <PastPerformance title={pastPerformance.title} data={pastPerformance.data} />
        </Grid>
        <Grid item xs={20} md={10} lg={6}>
          <Devices title={devices.title} data={devices.data} />
        </Grid>
        <Grid item xs={20} md={10} lg={10}>
          <ActualProjectedPower title={actualProjected.title} data={actualProjected.data} />
        </Grid>
        <Grid item xs={20} md={10} lg={10}>
          <InvertersPerformance title={invertersPerformance.title} data={invertersPerformance.data} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OverviewTab;
