import Box from '@mui/material/Box';
import { styled } from '@mui/system';

export const WidgetContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '16px',
  border: '1px solid #0000003B',
  height: '100%',
  minHeight: '330px'
}));
