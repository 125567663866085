import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import { ApiClient } from '../../../api';
import { useNotify } from '../../../contexts/notifications/notifications';
import { DeviceCategory, DeviceCategoryValue, DeviceTypesMap, DeviceManufacturersMap } from './constants';

const noBottomLineStyles = {
  '& .MuiInputBase-root:not(.Mui-disabled, .Mui-error)': {
    '&::before, &:hover::before, &.Mui-focused::after': {
      borderBottomColor: 'transparent',
      transform: 'scaleX(0)'
    }
  }
};

type AddDeviceParams = Parameters<typeof ApiClient.assetManagement.createDevice>[1];

type DeviceFormFields = {
  asset_id: string;
  status: 'Available Inventory' | 'Decommissioned' | 'Placed In Service' | 'RMA';
  name: string;
  category: DeviceCategoryValue;
  type: string;
  manufacturer: string;
  model: string;
  serial_number: string;
};

type DeviceFormProps = {
  mode: 'add';
  siteId: number;
  companyId: number;
};

export const DeviceForm: React.FC<DeviceFormProps> = ({ siteId, companyId }) => {
  const notify = useNotify();

  const { mutateAsync: addDevice } = useMutation({
    mutationFn: (attributes: AddDeviceParams) => ApiClient.assetManagement.createDevice(siteId, attributes)
  });

  const navigate = useNavigate();

  const { handleSubmit, formState, setError, setValue, control, reset, clearErrors, watch, trigger, resetField } =
    useForm<DeviceFormFields>({
      mode: 'onBlur',
      criteriaMode: 'all',
      reValidateMode: 'onBlur',
      defaultValues: {
        asset_id: undefined,
        status: undefined,
        name: undefined,
        category: undefined,
        type: undefined,
        manufacturer: undefined,
        model: undefined,
        serial_number: undefined
      }
    });

  const onSubmit: SubmitHandler<DeviceFormFields> = async data => {
    try {
      const message = await addDevice({
        asset_id: data.asset_id?.toLocaleUpperCase(),
        status: data.status,
        name: data.name,
        type: data.type === 'Other' ? null : data.type,
        manufacturer:
          data.manufacturer === 'Other'
            ? data.category === 'Network Connection'
              ? data.manufacturer
              : null
            : data.manufacturer,
        model: data.model,
        serial_number: data.serial_number,
        category: data.category
      });
      notify(message?.message || 'Device was added successfully.');
      reset();
      setTimeout(() => navigate(`/asset-management/companies/${companyId}/sites/${siteId}/devices`), 1000);
    } catch (e: any) {
      notify(
        e instanceof AxiosError
          ? e.response?.data?.message || e.message
          : e.message || 'Something went wrong when adding a new device...'
      );
      setError('root', {
        message: e.response?.data?.message || 'Device-entry creation failed'
      });
      setTimeout(() => {
        clearErrors('root');
        trigger();
      }, 5000);
    }
  };

  const { errors, isValid, isSubmitted, isSubmitSuccessful, isSubmitting, isDirty } = formState;
  const category = watch('category');

  const disableTypeSelection = React.useMemo(() => !category || DeviceTypesMap[category] === null, [category]);
  const deviceTypeOptions = React.useMemo(() => {
    const deviceTypes = DeviceTypesMap[category];
    return deviceTypes && deviceTypes.length ? (
      deviceTypes.map(type => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="Other">Other</MenuItem>
    );
  }, [category]);

  React.useEffect(() => {
    category && DeviceTypesMap[category] === null && setValue('type', 'Other');
  }, [category, setValue]);

  const disableManufacturerSelection = React.useMemo(
    () => !category || DeviceManufacturersMap[category] === null,
    [category]
  );
  const deviceManufacturerOptions = React.useMemo(() => {
    const deviceManufacturers = DeviceManufacturersMap[category];
    return deviceManufacturers && deviceManufacturers.length ? (
      deviceManufacturers.map(manufacturer => (
        <MenuItem key={manufacturer} value={manufacturer}>
          {manufacturer}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="Other">Other</MenuItem>
    );
  }, [category]);

  React.useEffect(() => {
    category && DeviceManufacturersMap[category] === null && setValue('manufacturer', 'Other');
  }, [category, setValue]);

  React.useEffect(() => {
    const subscription = watch((_value, { name, type }) => {
      if (name === 'category' && ['change', 'changeText', 'valueChange', 'selectionChange'].includes(type || '')) {
        resetField('type');
        resetField('manufacturer');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, resetField]);

  const handleClickBack = () => navigate(-1);

  return (
    <Stack component="form" noValidate width="30%" minWidth="320px" spacing={2} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="status"
        control={control}
        rules={{ required: 'Status is required field.' }}
        render={({ field }) => (
          <FormControl error={!!errors.status} variant="filled" required sx={noBottomLineStyles}>
            <InputLabel error={!!errors.status}>Status</InputLabel>
            <Select
              inputRef={field.ref}
              value={field.value || ''}
              error={!!errors.status}
              disabled={field.disabled}
              label="Status"
              onBlur={field.onBlur}
              onChange={field.onChange}
            >
              {['Available Inventory', 'Decommissioned', 'Placed In Service', 'RMA'].map(status => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
            {errors.status?.message && <FormHelperText error>{errors.status?.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        name="asset_id"
        control={control}
        rules={{
          required: 'Asset ID is required field.',
          pattern: {
            value: /^[a-zA-Z0-9]*$/,
            message: 'Asset ID should contain only alphabetic and numeric characters, spaces are not allowed.'
          },
          minLength: {
            value: 2,
            message: 'Asset ID length should be between 2 and 100 characters.'
          },
          maxLength: {
            value: 100,
            message: 'Asset ID length should be between 2 and 100 characters.'
          }
        }}
        render={({ field: { ref, value, ...field } }) => (
          <TextField
            {...field}
            variant="filled"
            required
            label="Asset ID"
            sx={{ ...noBottomLineStyles, input: { textTransform: 'uppercase' } }}
            helperText={errors.asset_id?.message}
            error={!!errors.asset_id}
            inputRef={ref}
            value={value || ''}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        rules={{
          required: 'Device Name is required field.',
          pattern: {
            value: /^[a-zA-Z0-9\s(){}[\]<>'".,/#!?$%^&*;:=\-+_`~@]*$/,
            message: 'Device Name should contain only alphabetic, numeric and special characters, spaces are allowed.'
          },
          minLength: {
            value: 2,
            message: 'Device Name length should be between 2 and 100 characters.'
          },
          maxLength: {
            value: 100,
            message: 'Device Name length should be between 2 and 100 characters.'
          }
        }}
        render={({ field: { ref, value, ...field } }) => (
          <TextField
            {...field}
            variant="filled"
            required
            label="Device Name"
            sx={noBottomLineStyles}
            helperText={errors.name?.message}
            error={!!errors.name}
            inputRef={ref}
            value={value || ''}
          />
        )}
      />
      <Controller
        name="category"
        control={control}
        rules={{ required: 'Device Category is required field.' }}
        render={({ field }) => (
          <FormControl error={!!errors.category} variant="filled" required sx={noBottomLineStyles}>
            <InputLabel error={!!errors.category}>Device Category</InputLabel>
            <Select
              inputRef={field.ref}
              value={field.value || ''}
              error={!!errors.category}
              disabled={field.disabled}
              label="Device Category"
              onBlur={field.onBlur}
              onChange={field.onChange}
            >
              {Object.values(DeviceCategory).map(category => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
            {errors.category?.message && <FormHelperText error>{errors.category?.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        name="type"
        control={control}
        rules={{ required: 'Device Type is required field.' }}
        render={({ field }) => (
          <FormControl error={!!errors.type} variant="filled" required sx={noBottomLineStyles}>
            <InputLabel error={!!errors.type}>Device Type</InputLabel>
            <Select
              inputRef={field.ref}
              value={field.value || ''}
              error={!!errors.type}
              disabled={disableTypeSelection}
              label="Device Type"
              onBlur={field.onBlur}
              onChange={field.onChange}
            >
              {deviceTypeOptions}
            </Select>
            {errors.type?.message && <FormHelperText error>{errors.type?.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        name="manufacturer"
        control={control}
        rules={{ required: 'Manufacturer is required field.' }}
        render={({ field }) => (
          <FormControl error={!!errors.manufacturer} variant="filled" required sx={noBottomLineStyles}>
            <InputLabel error={!!errors.manufacturer}>Manufacturer</InputLabel>
            <Select
              inputRef={field.ref}
              value={field.value || ''}
              error={!!errors.manufacturer}
              disabled={disableManufacturerSelection}
              label="Manufacturer"
              onBlur={field.onBlur}
              onChange={field.onChange}
            >
              {deviceManufacturerOptions}
            </Select>
            {errors.manufacturer?.message && <FormHelperText error>{errors.manufacturer?.message}</FormHelperText>}
          </FormControl>
        )}
      />
      <Controller
        name="model"
        control={control}
        rules={{
          required: 'Model is required field.',
          pattern: {
            value: /^[a-zA-Z0-9\s]*$/,
            message: 'Model should contain only alphabetic and numeric characters, spaces are allowed.'
          },
          minLength: {
            value: 2,
            message: 'Model length should be between 2 and 100 characters.'
          },
          maxLength: {
            value: 100,
            message: 'Model length should be between 2 and 100 characters.'
          }
        }}
        render={({ field: { ref, value, ...field } }) => (
          <TextField
            {...field}
            variant="filled"
            required
            label="Model"
            sx={noBottomLineStyles}
            helperText={errors.model?.message}
            error={!!errors.model}
            inputRef={ref}
            value={value || ''}
          />
        )}
      />
      <Controller
        name="serial_number"
        control={control}
        rules={{
          required: 'Serial # / Asset Tag is required field.',
          pattern: {
            value: /^[a-zA-Z0-9-]*$/,
            message:
              'Serial # / Asset Tag should contain only alphabetic, numeric and hyphen characters, spaces are not allowed.'
          },
          minLength: {
            value: 2,
            message: 'Serial # / Asset Tag length should be between 2 and 100 characters.'
          },
          maxLength: {
            value: 100,
            message: 'Serial # / Asset Tag length should be between 2 and 100 characters.'
          }
        }}
        render={({ field: { ref, value, ...field } }) => (
          <TextField
            {...field}
            variant="filled"
            required
            label="Serial # / Asset Tag"
            sx={noBottomLineStyles}
            helperText={errors.serial_number?.message}
            error={!!errors.serial_number}
            inputRef={ref}
            value={value || ''}
          />
        )}
      />
      <Stack direction="row" width="100%" spacing={3} justifyContent="stretch">
        <Button fullWidth variant="outlined" onClick={handleClickBack}>
          Back
        </Button>
        <Button disabled={!isValid || !isDirty || isSubmitting} fullWidth variant="contained" type="submit">
          Add
        </Button>
      </Stack>
      <Collapse in={isSubmitted && !isSubmitSuccessful && !!errors.root}>
        <Alert severity="error">{errors.root?.message}</Alert>
      </Collapse>
    </Stack>
  );
};
