import { RouteHandle } from '../../../../handles';
import { createAssetManagementDeviceDetailsLoader } from './loader';

type LoaderOutput = Awaited<ReturnType<ReturnType<typeof createAssetManagementDeviceDetailsLoader>>>;

export const createAssetManagementDeviceDetailsHandle = () => {
  const crumbsBuilder = (data: any) => {
    const resolvedData: LoaderOutput | undefined = data;

    if (!resolvedData || !resolvedData.deviceDetails || !resolvedData.siteDetails) {
      return [{ title: 'Companies', link: '/asset-management' }, { title: '...' }];
    }

    const {
      deviceDetails,
      siteDetails: { company: companyInfo, ...siteDetails }
    } = resolvedData;

    return [
      { title: 'Companies', link: '/asset-management' },
      { title: companyInfo.name, link: `/asset-management/companies/${companyInfo.id}` },
      { title: siteDetails.name, link: `/asset-management/companies/${companyInfo.id}/sites/${siteDetails.id}` },
      { title: deviceDetails.general_info.name }
    ];
  };

  return RouteHandle.createHandle({
    crumbsBuilder: crumbsBuilder,
    moduleId: 'asset-management'
  });
};
