import React from 'react';
import { AgChartOptions } from 'ag-charts-community';
import Typography from '@mui/material/Typography';
import { AgChartsReact } from 'ag-charts-react';
import { WidgetContainer } from '../../Overview.style';
import { formatFloatValue } from '../../../../../../../../utils/formatters/formatFloatValue';

interface InfoBoxProps {
  title: string;
  data?: {
    id: number;
    name: string;
    actual_kw: number;
    expected_kw: number;
    size: number;
  }[];
}

const ActualProductionVsProjected: React.FC<InfoBoxProps> = ({ title, data }) => {
  const options: AgChartOptions = {
    autoSize: true,
    height: 350,
    series: [
      {
        type: 'bubble',
        title: 'Energy',
        data: data,
        xKey: 'expected_kw',
        xName: 'Projected Energy, kW',
        yKey: 'actual_kw',
        yName: 'Actual Energy, kW',
        sizeKey: '',
        sizeName: '',
        labelKey: 'name',
        labelName: 'Name',
        marker: {
          size: 20
        },
        label: {
          enabled: true
        },
        tooltip: {
          renderer: params => {
            const { datum, xKey, yKey, xName, yName, title, labelName, labelKey } = params;
            const content = `
              <b>${labelName}: </b>${datum[labelKey || ''] ?? ''}<br />
              <b>${xName}: </b>${formatFloatValue(datum[xKey])}<br />
              <b>${yName}: </b>${formatFloatValue(datum[yKey])}`;
            return {
              title,
              content
            };
          }
        }
      }
    ],
    axes: [
      {
        type: 'number',
        position: 'bottom',
        title: {
          text: 'Projected Energy, kW'
        },
        label: {
          formatter: params => `${params.value / 1000}K`
        }
      },
      {
        type: 'number',
        position: 'left',
        title: {
          text: 'Actual Energy, kW'
        },
        label: {
          formatter: params => `${params.value / 1000}K`
        }
      }
    ]
  };

  if (!data) return null;

  return (
    <WidgetContainer>
      <Typography variant="h6" mb="6px">
        {title}
      </Typography>
      <AgChartsReact options={options} />
    </WidgetContainer>
  );
};

export default ActualProductionVsProjected;
